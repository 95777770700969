<template>
    <v-dialog v-model="dialog" persistent transition="scale-transition" fullscreen>
        <v-card class="elevation-1">
            <ValidationObserver ref="refLista" v-slot="{ invalid, validated }">
                <v-form name="form" @submit.prevent="saveLista">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="handleModal">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>
                        <v-toolbar-title>Nueva lista de precio</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn class="primary" type="submit" :disabled="invalid || !validated || productPriceEmpty || !allowToSaveListaPrice">
                                <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar lista
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-container fluid>
                            <v-row wrap>
                                <v-col cols="4">
                                    <ValidationProvider rules="required" v-slot="{errors, valid }">
                                        <v-text-field v-model="listaPrice.clave" label="Palabra clave de posterior busqueda.*"
                                        filled dense hint="Campo requerido.." persistent-hint prepend-inner-icon="fas fa-key"
                                        :error-messages="errors" :success="valid" required>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="8">
                                    <ValidationProvider rules="required" v-slot="{errors, valid }">
                                        <v-textarea label="Nota adicional*" rows="1" filled dense
                                        hint="Comentario adicional para esta lista de precio" prepend-inner-icon="fas fa-comment"
                                        persistent-hint v-model="listaPrice.nota" counter :error-messages="errors" :success="valid">
                                        </v-textarea>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row wrap>
                                <addListaPrecio ref="addListaPrecio" :cart.sync="dialog" :products.sync="products" @addProductPrice="addProductPrice"/>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>
<script>
import ListaPrecioDataService from "@/service/producto/ListaPrecioDataService";
import ProductoDataService from "@/service/producto/ProductoDataService";
import addListaPrecio from "./addListaPrice.vue";
export default {
    name: 'NuevaListaPrecio',
    props:{
        dialog: { default: false, type: Boolean },
        handleModal: { type: Function },
        initialize: { type: Function }
    },
    components: { addListaPrecio },
    created(){ this.initProducts() },
    data(){
        return {
            products: [],
            listaPrice: {
                nota: '',
                clave: '',
                actual: true,
                eliminado : false,
                productoPrice: []
            }
        }
    },
    methods: {
        initProducts(){
            ProductoDataService.findAll().then( resp => {
                this.products = resp.data;
            });
        },
        addProductPrice(productPrice){
            this.listaPrice.productoPrice = productPrice;
        },
        saveLista(){
            if(this.productPriceEmpty){
                this.$swal.fire({ icon: 'warning', text: 'Debe agregar por lo menos un producto', title: 'Error al guardar la lista de precio', showConfirmButton: false, timer: 3500 });
                return
            }
            if(this.allowToSaveListaPrice == false){
                this.$swal.fire({ icon: 'info',
                text: 'No se puede guardar una lista de precio, con al menos un producto con precio. Los productos con precios en cero o vacíos no se guardarán',
                showConfirmButton: false, timer: 4000 });
                return
            }
            this.$refs.refLista.validate().then( isValid => {
                if( isValid ){
                    ListaPrecioDataService.store(this.listaPrice).then( _ => {
                        this.initialize();
                        this.handleModal();
                        this.$swal.fire({icon: 'success', text: _.data.message, title: 'Lista de precio guardada', showConfirmButton: false, timer: 3000 });
                    }).catch( err => {
                        this.$swal.fire({ icon: 'error', html: err.response.data, title: 'Error al guardar la lista de precio', showConfirmButton: false, timer: 3000 });
                    });
                }
            });
        }
    },
    computed: {
        productPriceEmpty(){
            return this.listaPrice.productoPrice == undefined || this.listaPrice.productoPrice.length === 0;
        },
        listaPriceNullOrZero(){
            return this.listaPrice.productoPrice.filter( item => item.precioVenta === 0 || item.precioVenta === null || item.precioVenta === undefined || item.precioVenta === '');  
        },
        hasListaPrecioNullOrZero(){
            return this.listaPriceNullOrZero.length > 0;
        },
        allowToSaveListaPrice(){
            if(this.productPriceEmpty) return false;
            const itemPrices = this.listaPrice.productoPrice.length;
            if(this.listaPriceNullOrZero.length === itemPrices) return false;
            return true;
        }
    }
}
</script>