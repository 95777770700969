<template>
    <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-text-field v-model="search" label="Buscar producto para asignar precio" append-icon="fas fa-search"
                filled dense class="mt-2 mb--2" hide-details @keyup.enter.native="searchProduct">
            </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-simple-table fixed-header class="elevation-1" dense>
                <template v-slot:default>
                    <thead class="primary">
                        <tr>
                            <th class="text-left">Nombre</th>
                            <th class="text-left">Unidad venta</th>
                            <th class="text-left">Precio venta</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in searchProduct" :key="item.id">
                            <td>
                                <p class="h4 font-weight-black">{{ item.productoName }}</p>
                            </td>
                            <td>
                                <p class="h4 font-weight-black">{{ item.tipoUnidad.nombre }}</p>
                            </td>
                            <td>
                                <ValidationProvider rules="decimal" v-slot="{ errors, valid }" :immediate="true">
                                    <v-text-field v-model="item.precioVenta" type="number" label="Precio" class="mt-3"
                                        outlined persistent-hint prepend-inner-icon="fas fa-dollar-sign" required
                                        :error-messages="errors" :success="valid" suffix="MXN">
                                    </v-text-field>
                                </ValidationProvider>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>
<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'addListaPrice',
    props: {
        cart: { type: Boolean, default: false },
        mode: { type: String, default: 'add' },
        products: { type: Array },
        listProductPrice: { type: Array },
    },
    data() {
        return {
            search: '',
            productoListaPrecio: []
        }
    },
    methods: {
        generateByProduct(element){
            return {
                id: null,
                productoId: element.id,
                productoName: element.nombre,
                tipoUnidad: element.tipoUnidad,
                listaPrecioId: null,
                precioVenta: null,
                actual: true,
                eliminado: false
            };
            
        },
        generateProductPrice(products) {
            this.productoListaPrecio = products.map(product => this.generateByProduct(product));
        },
      
        checkListProductPrice(listProductPrice) {
            this.products.forEach(element => {
                let exist = listProductPrice.find(item => item.productoId == element.id);
                if(!exist){
                    this.productoListaPrecio.push(this.generateByProduct(element));
                } else {
                    const elementFound = this.generateByProduct(element);
                    elementFound.precioVenta = exist.precioVenta;
                    elementFound.listaPrecioId = exist.id;
                    elementFound.id = exist.id;
                    this.productoListaPrecio.push(elementFound);
                }
            });
        },
        emitDataProductPrice(productPrice) {
            this.$emit('addProductPrice', productPrice);
        }
    },
    watch: {
        products: function (newVal, oldVal) {
            if(this.mode == 'add'){
                this.generateProductPrice(newVal);
            }
        },
        productoListaPrecio: function (newVal, oldVal) {
            this.emitDataProductPrice(newVal);
        },
        listProductPrice: function (newVal, oldVal) {
            this.checkListProductPrice(newVal);
        }
    },
    computed: {
        searchProduct() {
            if (this.search == '') return this.productoListaPrecio
            return this.productoListaPrecio.filter((item) => {
                return item.productoName.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    },

}
</script>